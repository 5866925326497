<template>
  <pack-list></pack-list>
</template>

<script>
import PackList from "./../../components/packs/list";
export default {
  data: () => ({
  }),
  components: {
    PackList,
  },
};
</script>