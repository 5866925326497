<template>
  <div>
    <div class="text-h5 text-center font-weight-medium mt-8">{{ $t("pack.buy.title") }}</div>
    <div class="grey--text text-center text--darken-2 mb-8">{{ $t("pack.buy.description") }}</div>
    <div v-if="loading.pack" class="d-flex justify-center">
      <div class="text-center mt-5">
        <v-progress-circular indeterminate color="secondary" width="4" size="60"></v-progress-circular>
      </div>
    </div>
    <div v-else>
      <div v-if="packs.length" class="d-flex justify-center flex-wrap">
        <v-card elevation="1" v-for="(pack, k) in packs" :key="k" width="300" class="mx-4 mb-4">
          <v-card-text class="px-5">
            <p class="text-h5 font-weight-medium mb-0">{{ pack.title }}</p>
            <div class="grey--text text-body-2 mt-0">{{ pack.application.title.toUpperCase() }}</div>
            <div class="text-center mt-8">
              <span class="text-h4 primary--text font-weight-bold">{{ getPrice(pack.price) }}</span>
              <span>/</span>
              <span>{{ pack.unit.toUpperCase() }}</span>
            </div>
            <div class="text-center mt-2">
              <span>{{ formatQuantity(pack.quantity) }}</span>
              <span>&nbsp;{{ pack.unit.toUpperCase() }}</span>
            </div>
            <p class="mt-8">{{ pack.description }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :to="{ name: 'pack-show', params: { slug: pack.slug, id: pack.id } }"
              :color="pack.color"
              dark
              block
            >
              <span class="text-subtitle-1">{{ $t('btn.buy.title') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import PackFormatterMixin from "./../../mixins/packs/formatter";
import { mapActions } from "vuex";
export default {
  mixins: [PackFormatterMixin],
  created() {
    this.setPacks();
  },
  data: () => ({
    packs: [],
    loading: {
      pack: false
    }
  }),
  methods: {
    async setPacks() {
      this.loading.pack = true;
      try {
        const response = await this.request({
          url: "/api/packs?limit=50&sort=price,ASC",
          messages: { 500: true }
        });
        this.packs = response.data.data;
      } catch (error) {
        // empty
      }
      this.loading.pack = false;
    },

    ...mapActions({ request: "request" })
  }
};
</script>